import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";

import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";

import VueFormulate from "@braid/vue-formulate";

import VueCookie from "vue-cookie";

import wysiwyg from "vue-wysiwyg";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faQuestionCircle,
  faFaceLaugh,
  faFaceSmile,
  faFaceMeh,
  faFaceFrown,
} from "@fortawesome/free-regular-svg-icons";
import {
  faUserSecret,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowCircleDown,
  faInfoCircle,
  faEnvelope,
  faBookOpen,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faUpRightFromSquare,
  faFilePdf,
  faCircleXmark,
  faPoo,
  faCircleChevronDown,
  faChevronLeft,
  faChevronRight,
  faPeopleGroup,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add([
  faUserSecret,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowCircleDown,
  faInfoCircle,
  faEnvelope,
  faBookOpen,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faUpRightFromSquare,
  faFilePdf,
  faPoo,
  faQuestionCircle,
  faFaceLaugh,
  faFaceSmile,
  faFaceMeh,
  faFaceFrown,
  faCircleXmark,
  faCircleChevronDown,
  faChevronLeft,
  faChevronRight,
  faPeopleGroup,
  faUpload,
]);

Vue.component("vue-fontawesome", FontAwesomeIcon);

const axiosInstance = axios.create({
  baseURL: "https://www.gender-und-beruf.de/wp-json/",
  timeout: 5000,
});

Vue.use(VueAxios, axiosInstance);
Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(VueFormulate);

Vue.config.productionTip = false;
Vue.use(VueCookie);

Vue.use(wysiwyg, {
  locale: true,
  forcePlaneTextOnPaste: true,
  hideModules: { image: true },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Fontawesome
