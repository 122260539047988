import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";

// import Home from "../views/Home.vue";
// import About from "../views/About.vue";
// // import Page from "../views/Page.vue";
// import Methods from "../views/Methods.vue";
// import ShoppingCart from "../views/ShoppingCart.vue";
// import MethodDetail from "../views/MethodDetail.vue";
// import Kontakt from "../views/Kontakt.vue";
// // import Material from "../views/Material.vue";
// import Impressum from "../views/Impressum.vue";
// import Datenschutz from "../views/Datenschutz.vue";
// import Infothek from "../views/Infothek";
// import MethodPdfDownload from "../views/MethodPdfDownload";
// import Lerneinheiten from "../views/Lerneinheiten";
// import WorkshopOverview from "../views/WorkshopOverview.vue";
// import WorkshopDetail from "../views/WorkshopDetail.vue";
// import WorkshopRequest from "../views/WorkshopRequest.vue";
// import Team from "../views/Team.vue";
// import News from "../views/News.vue";
// import NewsDetail from "../views/NewsDetail.vue";
// import MethodSubmit from "../views/MethodSubmit";

Vue.use(VueRouter);

const baseURL = "https://www.gender-und-beruf.de/wp-json";

function lazyload(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: lazyload("Home"),
    meta: {
      slug: "home",
    },
  },
  {
    path: "/ueber-das-projekt",
    name: "About",
    component: lazyload("About"),
    meta: {
      slug: "about",
    },
  },
  {
    path: "/methoden",
    name: "Methoden",
    component: lazyload("Methods"),
    meta: {
      slug: "methodenuebersicht",
    },
  },
  {
    path: "/methode/:id",
    name: "MehtodsDetail",
    component: lazyload("MethodDetail"),
    meta: {
      slug: "methode-detailansicht",
    },
  },
  {
    path: "/mein-methodenset",
    name: "ShoppingCart",
    component: lazyload("ShoppingCart"),
    meta: {
      slug: "mein-methodenset",
    },
  },
  {
    path: "/kontakt/workshop/:workshop_id?",
    name: "WorkshopRequest",
    component: lazyload("WorkshopRequest"),
    meta: {
      slug: "kontakt-workshop",
    },
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: lazyload("Kontakt"),
    meta: {
      slug: "kontakt",
    },
  },
  {
    path: "/das-team",
    name: "Team",
    component: lazyload("Team"),
    meta: {
      slug: "team",
    },
  },
  {
    path: "/infothek",
    name: "Infothek",
    component: lazyload("Infothek"),
    meta: {
      slug: "infothek",
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: lazyload("Impressum"),
    meta: {
      slug: "impressum",
    },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: lazyload("Datenschutz"),
    meta: {
      slug: "datenschutz",
    },
  },
  {
    path: "/lerneinheiten",
    name: "Lerneinheiten",
    component: lazyload("Lerneinheiten"),
    meta: {
      slug: "lerneinheiten",
    },
  },
  {
    path: "/download",
    name: "Download",
    component: lazyload("MethodPdfDownload"),
  },
  {
    path: "/workshops",
    name: "WorkshopOverview",
    component: lazyload("WorkshopOverview"),
    meta: {
      slug: "workshop-uebersicht",
    },
  },
  {
    path: "/workshops/:id",
    name: "WorkshopDetail",
    component: lazyload("WorkshopDetail"),
    meta: {
      slug: "workshop-detailansicht",
    },
  },
  {
    path: "/aktuelles",
    name: "Aktuelles",
    component: lazyload("News"),
    meta: {
      slug: "aktuelles",
    },
  },
  {
    path: "/aktuelles/:newsSlug",
    name: "Newsbeitrag",
    component: lazyload("NewsDetail"),
  },
  {
    path: "/methode-einreichen",
    name: "MethodSubmit",
    component: lazyload("MethodSubmit"),
    meta: {
      slug: "methode-einreichen",
    },
  },
  {
    path: encodeURI("/faktenfunk"),
    name: "MemoryOvervuew",
    component: lazyload("MemoryOverview"),
    meta: {
      slug: "faktenfunk",
    },
  },
  {
    path: encodeURI("/faktenfunk/:id"),
    name: "Memory",
    component: lazyload("MemoryDetail"),
    meta: {
      slug: "faktenfunkdetailansicht",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.includes("/#")) {
    router.replace(to.fullPath.replace("/#", ""));
    return;
  }
  next();
});

router.beforeResolve(async (to, from, next) => {
  // ToDo: fetch SEO information from Backend
  axios
    .post(
      baseURL + "/statistics/v2/log-daily-visit",
      {},
      { withCredentials: true }
    )
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });

  window.scrollTo(0, 0);

  console.log(to);
  if (to.meta.slug) {
    axios
      .post(
        "https://www.gender-und-beruf.de/wp-json/statistics/v2/log-route-visit",
        {
          url: to.fullPath,
          slug: to.meta.slug,
        }
      )
      .then((res) => console.log(res));
    axios
      .get("https://www.gender-und-beruf.de/wp-json/wp/v2/pages", {
        params: { slug: to.meta.slug },
      })
      .then((res) => {
        to.meta.title =
          res.data[0].acf.page_title.length > 0
            ? res.data[0].acf.page_title
            : to.name;
        console.log(res.data[0].acf);
        to.meta.meta = [];
        to.meta.meta.push({
          name: "description",
          content: res.data[0].acf.meta_description,
        });
        to.meta.meta.push({
          name: "keywords",
          content: res.data[0].acf.meta_keywords,
        });
        window.scrollTo(0, 0);
        console.log(to.meta);
        next();
      })
      .catch((err) => {
        console.log(
          "could not get meta info for page: " +
            to.name +
            " with slug: " +
            to.meta.slug
        );
        console.log(err);
        window.scrollTo(0, 0);
        next();
      });
  } else {
    console.log("no slug found");
    next();
  }
  // next();
});

export default router;
