import Vue from "vue";
import Vuex from "vuex";
import createPersitedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    itemCount: 0,
    cartContent: [],
    filterValues: [],
    is_mobile: false,
  },
  mutations: {
    addItemToCart(state, method) {
      if (!state.cartContent.includes(method.method)) {
        state.cartContent.push(method.method);
        state.itemCount = state.cartContent.length;
      }
    },
    clearCart(state) {
      state.itemCount = 0;
      state.cartContent = [];
    },
    removeItemFromCart(state, item) {
      state.cartContent = state.cartContent.filter(
        (cartItem) => cartItem.id != item.id
      );
      state.itemCount = state.cartContent.length;
    },
    updateList(state, value) {
      state.cartContent = value;
    },
    addFilter(state, filter) {
      if (!state.filterValues.includes(filter)) {
        state.filterValues.push(filter);
      }
    },
    removeFilter(state, filter) {
      state.filterValues = state.filterValues.filter((elem) => elem !== filter);
    },
    updateFilter(state, filter) {
      state.filterValues = filter;
    },
    clearFilter(state) {
      state.filterValues = [];
    },
    set_is_mobile(state, is_mobile) {
      state.is_mobile = is_mobile;
    },
  },
  actions: {},
  getters: {
    isInCart: (state) => (id) => {
      let tmp = state.cartContent.find((method) => method.id === id);
      return tmp !== undefined;
    },
    is_mobile: (state) => state.is_mobile,
  },
  modules: {},
  plugins: [
    createPersitedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 1, secure: false }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});
